import { Theme } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import moment from "moment";
import {ReactComponent as Clock} from '../resources/icons/timerclock.svg';
import React, {useState, useEffect, useRef} from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    timerContainer: {        
        marginRight: 20,
        background: "rgba(0, 160, 223, 1)",
        // filter: 'drop-shadow(0px 10px 22px rgba(197, 139, 242, 0.30))',        
        boxShadow: "0 2px 2px rgba(64, 64, 64, 1)",
        textAlign: "center",
    },
    timerNumbers: {
        color: 'black',
        fontSize: 30,    
        fontWeight: 400,
        fonstStyle: 'normal',
        lineHeight: '30px',
        marginBottom: 10,        

    },
    belowNumbers: {
        display: "flex",
        alignItems: "center",
        color: 'black',
        fontSize: '0.625rem',
        fontWeight: 400,
        fontFamily: "Poppins",
        margin: 0,
        marginBottom: 5
        
    },
}))

const Timer = ({initialTime, unit = 'sec', onTimerEnd}) => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const initialTimeInSeconds = unit === 'min' ? initialTime * 60 : initialTime;
    const [remainingTime, setRemainingTime] = React.useState(initialTimeInSeconds);
    const [isTimerRunning, setIsTimerRunning] = React.useState(false);
    const [isTimerEnded, setIsTimerEnded] = React.useState(false);
    const timerRef = useRef(null);
    moment.locale(i18n.language);

    // useEffect(() => {
    //     setRemainingTime(initialTime);
    // }, [initialTime]);

    const handleTimerClick = () => {
        if(isTimerEnded) {
            setRemainingTime(initialTimeInSeconds);
            setIsTimerEnded(false);
        } else {
            setIsTimerRunning(prevState => !prevState);
        }        
    }
    useEffect(() => {
        if(isTimerRunning) {
            timerRef.current = setInterval(() => {
                setRemainingTime(prevTime => {
                    if(prevTime > 0) {
                        return prevTime - 1;
                    } else {                        
                        clearInterval(timerRef.current);
                        setIsTimerRunning(false);
                        setIsTimerEnded(true);
                        if(onTimerEnd) {
                            onTimerEnd();
                        }
                        return 0;
                    }
                })
            }, 1000);
        } else if(!isTimerRunning && timerRef.current) {
            clearInterval(timerRef.current);
        }
        return () => clearInterval(timerRef.current);
    }, [isTimerRunning, onTimerEnd, initialTimeInSeconds]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }
    
    return (
        <div 
        className={classes.timerContainer}
        style={{            
            cursor: isTimerRunning ? 'default' : 'pointer',           
            width: '108px',
            height: '108px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',                        
            borderRadius: '50%',     
            background: isTimerEnded ? 'rgb(70, 219, 103)' : 'rgba(0, 160, 223, 1)',
            // background: "linear-gradient( 314deg, rgba(70,169,219,0.75) 22.62%, rgba(25, 118, 210, 0.75) 56.24%);",
            
        }}
            onClick={handleTimerClick}
        >
             <span className={classes.timerNumbers} style={{margin: 0}}>
                {formatTime(remainingTime)}
            </span>
            {isTimerRunning && !isTimerEnded && (
                <p className={classes.belowNumbers}>
                    {t('activity_page.pause')}
                </p>
            )}
            
            {!isTimerRunning && !isTimerEnded && (
                <p className={classes.belowNumbers}>
                    {remainingTime === initialTimeInSeconds ? t('activity_page.tap_to_start') : t('activity_page.continue')}
                </p>
            )}
            
            {isTimerEnded && (
                <p className={classes.belowNumbers}>
                    {t('activity_page.reset')}
                </p>
            )}
            <Clock />
        </div>
    );
};

export default Timer;