import { useEffect } from "react";
import { getTrainings, getVisits, createVisit, getLatestTrainingSession } from "../services/api";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import {Loading} from "../components";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import { CheckBox, TransgenderTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Radio } from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {
    AtHomeIcon,
    ClickedHomeIcon,
    AtGymIcon,
    ClickedGymIcon,
    BackArrow,
} from '../resources'
import moment from 'moment';
import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        minheight: "100vh",      
        width: "100%",
        display: "flex",
        flexDirection: "column",        
        paddingBottom: theme.spacing(10),
    },
    fixed: {                
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        background: "white",
        zIndex: 100,             
        display: "flex",
        flexDirection:"column",
        width:"100%",
        maxWidth:"360px",
        margin: "0 auto",        
        
        
    },
    content: {
        flex: 1,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column"
    },
    title: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize:"16px",
        paddingLeft: 20,
        color: "#404040",
    },
    topDiv: {          
        display: "flex",
        flexDirection: "row",        
        alignItems: "center",
        paddingLeft: 20,
        paddingTop: 15,
        height: 70,        
        width: "100%",        
    },
    workouts: {
        width: "100%",
        display: "flex",
        flexDirection: "column",        
        justifyContent: "center",   
        alignItems: "center",
        paddingTop: 280,
        
    },
    workoutPaper: {
        display: "flex",        
        justifyContent: "center", 
        width: "330px",
        height: "179px",
        borderRadius: 20,                
        marginBottom: 20, 
        position: "relative",
        overflow: "hidden",
    },
    paperlayout: {
        display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderTopRightRadius: "20px",
		borderBottomRightRadius: "20px",
		borderBottomLeftRadius: "20px",
		background:"rgba(255, 255, 255, 0.8)",
        height: "60px",
        marginTop: "auto",
        width: "100%"
    },
    workoutTitle: {        
        marginTop: 10,
        marginBottom: 0,
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "14px",
        color:"#404040"

    },
    workoutdesc: {                                        
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "20px",        
    },
    startTraining: {
        width: "100px",
		height: "34px",
		background: "#0F5486",
		borderRadius: "50px",
		color: "white",		
		fontSize: "10px",
		alignItems:"center",
        marginRight: "10px",
        boxShadow: "0 2px 2px #404040",
        textTransform: "none",
    },
    previewButton: {
        position: "absolute",
		background: "white",
		borderRadius: "50px",
		color: "#404040",		
		fontSize: "10px",
		alignItems:"center",
        marginRight: "10px",
        boxShadow: "0 2px 2px #404040",
        textTransform: "none",

    },
    arrowButton: {        
        height: "32px",        
        width: "32px",
        borderRadius: "8px",
        background: "#F7F8F8",
        marginRight: "54px",
        
    },
    locationButtonContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%" ,                
        
        
    },
    locationButton: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "rgba(64, 64, 64, 1)",
        minWidth: "86px",
        minHeight: "78px",
        borderRadius: "10px",
        border: "1px solid #ADA4A5",
        transition: "background 0.3s, color 0.3s",
        boxShadow: "0px 2px 2px rgba(64, 64, 64, 1)",    
        marginRight: "40px",
        textTransform: "none",
        fontSize: 11,
        fontWeight: 600,
        
        
    },
    locationHomeButton: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",        
        color: "rgba(64, 64, 64, 1)",
        minWidth: "86px",
        minHeight: "78px",
        borderRadius: "10px",
        border: "1px solid #ADA4A5",
        transition: "background 0.3s, color 0.3s",
        boxShadow: "0px 2px 2px rgba(64, 64, 64, 1)",    
        marginLeft: "30px",    
        marginRight: "20px",
        textTransform: "none",
        fontSize: 11,
        fontWeight: 600,
        
    },
    clickedLocationButton: {
        color: "white",
        background: '#F28986',
        '&:hover': {
            background:'#F28986'
        },
        '&:focus': {
			backgroundColor: "#F28986",
			outline: "none"
		}
    },
    clickedGymLocationButton: {
        color: "white",
        background: '#00A0DF',
        '&:hover': {
            background:'#00A0DF'
        },
        '&:focus': {
			backgroundColor: "00A0DF",
			outline: "none"
		}
        
    },
    clickedIcon: {
        fill: "white"
    },
    radioButton: {
        display: "flex",
        justifyContent: "flex-end",
        width: "fit-content",
    }
}))

export function StartTrainingPage () {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const perPage = 100;
    const navigate = useNavigate();
   
    const [loadedPage, setLoadedPage] = React.useState(0);
    const [trainings, setTrainings] = React.useState([]);
    const [trainingsData, setTrainingData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [isChecked, setIsChecked] = React.useState(false);
    const [selectedVisitType, setSelectedVisitType] = React.useState(4);
    const [selectedTrainingId, setSelectedTrainingId] = React.useState(0);
    const [ongoingTrainingSession, setOngoingTrainingSession] = React.useState(null);
    const [error, setError] = React.useState(null);    
    const currentUser = useSelector((state: RootState) => state.user);
    const [imageCache, setImageCache] = React.useState({});
    

    moment.locale(i18n.language);
    // const handleChange = (event) => {
    //     const visitType = event.target.textContent.trim();
    //     if(visitType === t('common.home')) {
    //         setSelectedVisitType(4);
    //     } else if(visitType === t('common.gym')) {
    //         setSelectedVisitType(1);
    //     }
    // }
    
    // const setVisitType = (event) => {
    //     setSelectedVisitType(parseInt(event.target.value, 10));
    // }
    const iconStyle = {
        fill: 'white'
    }

    const handleChange = (event) => {
        const selectedValue = parseInt(event.currentTarget.value);
        setSelectedVisitType(selectedValue);
        
    }

    const handlePreviewClick = (trainingId) => {
        navigate(`/app/workoutpreview/${trainingId}`)
    }
    
    console.log("handleChange", selectedVisitType);

    useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const visitResponse = await getLatestTrainingSession(
					currentUser.id
				);
				let lastVisit = null;
				if(visitResponse?.results?.length > 0){
					lastVisit = get(visitResponse, "results[0]");
                    setOngoingTrainingSession(lastVisit);
				}else{
					lastVisit = null;
				}
				if (lastVisit) {
					navigate(`/app/workouts/${lastVisit.id}`);
                    
				} else {
					const _trainings = await getTrainings(currentUser.id);
					if(_trainings?.count > 0){
						setTrainings(_trainings.results);
					}else{
						setTrainings([]);
					}
				}
			} catch (e) {
				console.log(e);

			}
			setLoading(false);
		}
        initData();
        // eslint-disable-next-line
	}, []);

    useEffect(() => {
        async function initData() {
            const nextPage = loadedPage + 1;
            setLoading(true);
            try {
                const data = await getVisits(currentUser.id, nextPage, perPage);
                const trainings = await getTrainings(currentUser.id)
                setTrainings(trainings.results)
                setTrainingData(data.results)

            } catch (err) {
                console.log(err);
            }
            setLoading(false);
        }
        initData();
    }, [currentUser.id, loadedPage]);

    const onCreateSession = async (trainingId) => {
        if(!trainingId) {
            setError("Please select a training");
            return;
        }
        const selectedTraining = trainings.find(
			(training) => training.id === trainingId
		);
        if(!selectedTraining) {
            setError("Please select location")
            return;
        }
        setError(null);
        setLoading(true);
        try {
            const newVisit = await createVisit({
                login: new Date().toISOString(),
                logout: null,
                user: currentUser.id,
                visit_type: selectedVisitType,
                training_program_name: selectedTraining.name,
                training_program: selectedTraining.id,
                facility: currentUser.membership.facility,
                comments: 'Created through the app',
            });
            console.log("New visit created", newVisit)
            navigate(`/app/workouts/${newVisit.id}`);
        } catch(e){
            console.log(e);
        }
        setLoading(false);
    }
    const handleContinueTraining = (visitId) => {
        navigate(`/app/workouts/${visitId}`);
        
    }

    console.log("trainings", trainings);
    // console.log("trainingsData", trainingsData)
    const trainingsCount = trainings.length;
    const amountOfActivities = [];
    
    trainings.forEach((training) => {
        const programName = training.name
        const activities = training.activities.length;
        amountOfActivities[programName] = activities;
    })

    const fallBackImages = [
		"TEK_1633.jpg",
		"TEK_1650.jpg",
        "TEK_2013.jpg",
        "TEK_1944.jpg",
        "TEK_1768.jpg",
        "TEK_1987.jpg",
        "TEK_2261.jpg",
	]	

    const serverUrl = localStorage.getItem("server_url");
	const cdnAddress = "https://cdn01.hursmarttouch.com"    

    function getRandomFallBackImageUrl() {
		const randomIndex = Math.floor(Math.random() * fallBackImages.length)
            return `${cdnAddress}/media/training_images/gym_training/${fallBackImages[randomIndex]}` 
        }
		
	function getImageUrl(program, index) {
		const image = program.training_picture;
		if(image && image.trim() !== "") {
			return `${serverUrl}/media/${image}`
		} else {
            if(index < 7) {
			return `${cdnAddress}/media/training_images/gym_training/${fallBackImages[index]}` 
          } else {
            return getRandomFallBackImageUrl();
          } 
		}
	}

    useEffect(() => {
        const newImageCache = {...imageCache};
        trainings.forEach((program, index) => {
            if(!newImageCache[program.id]) {
                newImageCache[program.id] = getImageUrl(program, index);
            }
        })
        setImageCache(newImageCache);
    }, [trainings])

    return (
        <div className={classes.root}>              
            <div className={classes.fixed}>        
            <div className={classes.topDiv}>
            <Button  component={RouterLink} to="/app/dashboard" style={{paddingLeft:0, marginRight: "54px"}}><BackArrow /></Button>
            <h3 className={classes.title}>{t('common.exercises')}</h3>
            </div>            
            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <h3 style={{width: "100%",marginLeft: "30px", fontSize: "16px", fontWeight: 600, fontFamily: "Poppins", color: "#404040"}}>{t('workouts_page.location')}</h3>
            <div className={classes.locationButtonContainer}>
                <Button 
                className={`${classes.locationHomeButton} ${selectedVisitType === 4 ? classes.clickedLocationButton : ''}`}
                value = '4' 
                onClick={handleChange}
                >
                    {t('workouts_page.home')}
                    {selectedVisitType === 4 ? (
                        <ClickedHomeIcon className={classes.clickedLocationButton} />
                    ) : (
                        <AtHomeIcon />
                    )}
                    
                    </Button>
                <Button 
                className={`${classes.locationButton} ${selectedVisitType === 1 ? classes.clickedGymLocationButton : ''}`} 
                value = '1' 
                onClick={handleChange}
                >
                    {t('workouts_page.gym')}
                    {selectedVisitType === 1 ? (
                        <ClickedGymIcon className={classes.clickedGymLocationButton}/>
                    ) : (
                        <AtGymIcon />
                    )}                    
                    </Button>
            </div>
            </div>
            <h4 style={{marginBottom: 25, marginLeft: "30px", fontWeight: 500, fontFamily: "Poppins", fontSize: "14px"}}>{trainingsCount} {t('common.exercise_programs')}</h4>
            </div>                     
            <div className={classes.workouts}>                
                <div>
                {trainings.map((training, index) => {       
                    const imageUrl = imageCache[training.id]
                    return (
                    <Paper key={index} className={classes.workoutPaper} style={{backgroundImage: `url(${imageUrl})`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}> 
                        <div style={{
                            position: "absolute",                            
                            left: 15,                            
                            marginTop: 5                                                   
                        }}>
                        <Button className={classes.previewButton} onClick={() => handlePreviewClick(training.id)}>{t('common.preview_training')}</Button>                                                
                        </div>
                        <div className={classes.paperlayout}>  
                        <div className={classes.workoutdesc}>
                        <h4 className={classes.workoutTitle}>{training.name}</h4>
                        <p style={{margin: 0,fontSize: "0.93rem", fontWeight: 400, color: "#404040", fontFamily: "Poppins" }}>{amountOfActivities[training.name]} {t('common.exercises')}</p>                        
                        </div>
                        <Button className={classes.startTraining} onClick={() => onCreateSession(training.id)} disabled={loading}>{t('common.start_training')}</Button>                                                                        
                        </div>
                    </Paper>
                    );
                })}                    
                </div>
             </div>
            </div>                        
    );
}