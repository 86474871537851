import React from 'react';
import {PieChart, Pie, Legend, Tooltip, Text} from 'recharts';
import { useTranslation } from 'react-i18next';
import moment from 'moment';


export default function WorkoutProgress ({weekGoalThisWeekData}) {

    const {t, i18n} = useTranslation();
    
    moment.locale(i18n.language);

    const homePercent = Math.floor(weekGoalThisWeekData.home.percent);
    const gymPercent = Math.floor(weekGoalThisWeekData.gym.percent);
    const totalProgress = Math.floor(weekGoalThisWeekData.all.percent);



    const outerData = [
       {name: 'Home Workout', value: homePercent, fill: '#F28986'},    
       {value: 100 - (homePercent), fill: 'rgba(242, 137, 134, 0.2)'}      
    ];

    const insideData = [
        {name: 'Gym Workout', value: gymPercent, fill: '#46A9DB'},
        {value: 100 - (gymPercent), fill: 'rgba(70, 169, 219, 0.2)'}
    ]
    console.log("Home, Gym and all progress in WorkoutProgress", homePercent, gymPercent, totalProgress);

    const hasData = homePercent > 0 || gymPercent > 0;
    console.log("Home Percent, Gym Percent", homePercent,gymPercent)

    const calculateCenter = (entry) => {
        const centerX = entry.x || 0;
        const centerY = entry.y || 0;
        const innerRadius = entry.innerRadius || 0
        const outerRadius = entry.outerRadius || 0
        return {centerX, centerY, innerRadius, outerRadius}
    }

    function getArcLength(startAngle,endAngle, radius) {
        const startAngleRad = startAngle * (Math.PI / 180);
        const endAngleRad = endAngle * (Math.PI / 180);

        const centralAngleRad = Math.abs(endAngleRad - startAngleRad);
        const arcLength = centralAngleRad * radius;

        return arcLength;
    }  

    const preventOverlap = (x, y, otherLabels, gap = 20) => {
      for (let i = 0; i < otherLabels.length; i++) {
        const { x: otherX, y: otherY } = otherLabels[i];
        const distance = Math.sqrt((x - otherX) ** 2 + (y - otherY) ** 2);
        if (distance < gap) {
          return {
            x: x + gap * Math.sign(x - otherX),
            y: y + gap * Math.sign(y - otherY),
          };
        }
      }
      return { x, y };
    };

    const renderOuterLabel = (props, otherLabels) => {
        const { cx, cy, midAngle, outerRadius, name } = props;
        const radius = outerRadius + 7; // Adjusting for outer pie
        const adjustedMidAngle = midAngle - 100;
        const x1 = cx + outerRadius * Math.cos(-adjustedMidAngle * Math.PI / 180);
        const y1 = cy + outerRadius * Math.sin(-adjustedMidAngle * Math.PI / 180);
        const x2 = cx + radius * Math.cos(-adjustedMidAngle * Math.PI / 180);
        const y2 = cy + radius * Math.sin(-adjustedMidAngle * Math.PI / 180);
        const label = `${homePercent}%`;
    
        if (name !== 'Home Workout') return null; // Ensure label only appears once

        const {x: finalX, y: finalY} = preventOverlap(x2, y2, otherLabels);
        otherLabels.push({x: finalX, y: finalY});
        const gap = 3
        return (
          <g>
            <line x1={x1} y1={y1} x2={finalX} y2={finalY} stroke="black" />
            <Text
              x={finalX + (finalX > cx ? gap : -gap)}
              y={finalY + (finalY > cy ? gap : -gap)} 
              fill="black"
              textAnchor={finalX > cx ? 'start' : 'end'}
              dominantBaseline="central"
              fontSize={16}
              fontWeight={700}
              fontFamily={"Poppins"}
            >
              {label}
            </Text>
          </g>
        );
      };
    
      const renderInnerLabel = (props, otherLabels) => {
        const { cx, cy, midAngle, outerRadius, name } = props;
        const radius = outerRadius + 42; // Adjusting for inner pie
        const adjustedMidAngle = midAngle - 25
        const x1 = cx + outerRadius * Math.cos(-adjustedMidAngle * Math.PI / 180);
        const y1 = cy + outerRadius * Math.sin(-adjustedMidAngle * Math.PI / 180);
        const x2 = cx + radius * Math.cos(-adjustedMidAngle * Math.PI / 180);
        const y2 = cy + radius * Math.sin(-adjustedMidAngle * Math.PI / 180);
        const label = `${gymPercent}%`;
    
        if (name !== 'Gym Workout') return null; // Ensure label only appears once
        const {x: finalX, y: finalY} = preventOverlap(x2, y2, otherLabels);
        otherLabels.push({x: finalX, y: finalY});
        const gap = 3
        return (
          <g>
            <line x1={x1} y1={y1} x2={finalX} y2={finalY} stroke="black" />
            <Text
              x={finalX + (finalX > cx ? gap : -gap)}
              y={finalY + (finalY > cy ? gap : -gap)} 
              fill="black"
              textAnchor={finalX > cx ? 'start' : 'end'}
              dominantBaseline="central"
              fontSize={16}
              fontWeight={700}
              fontFamily={"Poppins"}
            >
              {label}
            </Text>
          </g>
        );
      };
    

    const textColor = (value, entry) => {
        return (
            <span style= {{
              color: '#404040',
              fontFamily: "Poppins", 
              fontWeight: 400, 
              fontSize: "0.625rem",                                                                               
            }}
            >
              {value}
              </span>
        )
    }   
    const otherLabels = [];

    return(
        <div style={{
          pointerEvents: "none",
          textOverflow: "ellipsis",
          }}>
        <PieChart width={400} height={280} onClick={() => {}}>          
        <Pie
            data={outerData}
            fill='#BDBDBD'
            dataKey="value"       
            cx={200}
            cy={115}
            outerRadius={105}
            innerRadius={70}
            label={(props) => renderOuterLabel(props, otherLabels)}
            labelLine={false}
            startAngle={450}
            endAngle={90}            
            />
            <Pie
            data={insideData}
            fill='#BDBDBD'
            dataKey="value"
            cx={200}
            cy={115}
            outerRadius={70}
            innerRadius={35}
            label={(props) => renderInnerLabel(props, otherLabels)}
            labelLine={false}
            startAngle={450}
            endAngle={90}  
            />                     
        <Legend
            iconType='circle'
            iconSize={20}
            align='center'
            wrapperStyle= {{                                        
            flexWrap: 'wrap',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            minWidth: '100%',                                         
            marginBottom: "5px",            
            marginRight: "auto",
            }}
            payload={[
                {value: t('common.home_workout'), type: 'circle', id: 'legend-1', color: '#F28986' },
                {value: t('common.gym_workout'), type: 'circle', id: 'legend-2', color: '#46A9DB' }
            ]}
            formatter={textColor}
            
            />                                                                      
        </PieChart>
        </div>
        
    );
};