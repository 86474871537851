import {
	Button,
	FormControl,
	InputLabel,
	Link,
	Menu,
	MenuItem,
	Select,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Axios from "axios";
import useAxios from "axios-hooks";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useActions } from "../actions";
import * as UserActions from "../actions/auth";
import logo from "../resources/img/for_lifelong.png";
import { getAccount } from "../services/api";
import authenticator from "../services/authenticator";
import { useTranslation } from 'react-i18next';
import InstallPWAButton from "../components/InstallPWAButton";
import { set } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";


const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		borderRadius: 99,
		height: 47,
		width: 201,
		background: "rgba(15, 84, 134, 1)",
		textTransform: "none",
		
	},
	submitText: {
		fonstSize: "16px",
		fontWeight: 700,
		fontFamily: "Poppins",
	},
	select: {
            borderRadius: 14,
            borderColor: '#00A0DF',
		borderWidth: '2px',
		'& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00A0DF',
			borderWidth: "2px"
        },		       
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 14,
            '& fieldset': {
                borderColor: '#00A0DF',
				borderWidth: '2px'
            },
            '&:hover fieldset': {
                borderColor: '#00A0DF',
				borderWidth: '2px'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#00A0DF',
				borderWidth: '2px'
            },
        },
    },
	menuPaper: {
		maxHeight: 300,		
		borderRadius: 14,

	},
	menuItem: {
		borderBottom: '1px solid black',
        '&:last-child': {
        borderBottom: 'none',
	},
},
}));

function Copyright() {
	return (
		<Typography variant="body2" color="black" align="center">
			{"Copyright © "}
			<Link color="inherit" href="https://myhurgym.com/">
				Ab HUR Oy (0.3.39b)
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
		
	);
}

export function LoginPage(props) {
	const authActions = useActions(UserActions);
	const navigate = useNavigate()
	const classes = useStyles();
	const onLoginRedirectUrl = "/app/dashboard";
	const onFirstLoginRedirectUrl = "/app/onboarding"
	const { t, i18n } = useTranslation();
	const [, setLoaded] = useState(false);
	const [server, setServer] = useState(null);
	const [loggedIn, setLoggedIn] = useState(false);
	const [, setError] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [accessingGym, setAccessingGym] = useState(false);
	const [checkedFirstLogin, setCheckedFirstLogin] = useState(false);
	const [language, setLanguage] = React.useState(i18n.language);
	const currentUser = useSelector((state: RootState) => state.user)	
	//const [urlMatchedHost, setUrlMatchedHost] = useState(null);	
	const changeLanguage = lng => {
	  i18n.changeLanguage(lng);
	};	
	const [{ data, loading }] = useAxios(
		"https://myhurgym-public.s3.eu-north-1.amazonaws.com/hur_smarttouch_hosts_public.json"
	);
	
	const servers = [];
	const hosts = [];
	let urlMatchedHost = null;
	if (loading === false) {
		// get part of url to match
		let currentRelevantUrl = window.location.href.split('/')[2].split(':')[0];
		for (let i = 0; i < data.hosts.length; i += 1) {
			const host = data.hosts[i];
			servers.push(
				<MenuItem key={host.uuid} value={host.uuid}>
					{host.name}
				</MenuItem>
			);
			hosts.push(host);
			// check if url matches with one from the list
			if ("myhurgym_address" in host && host.myhurgym_address == currentRelevantUrl){
				urlMatchedHost = {...host};				
			}
		}		 
	}	
	
	useEffect(() => {
		const isLoggedIn = authenticator.isLoggedIn();
		if (isLoggedIn) {
			setLoaded(true);
			setLoggedIn(true);
		} else {
			setLoaded(true);
		}
	}, []);

	useEffect(() => {
		const lastUsedServer = localStorage.getItem('lastUsedServer');
		if(lastUsedServer) {
			setServer(lastUsedServer)
		}
	})

	useEffect(() => {
		const isFirstLogin = localStorage.getItem('isFirstLogin')
		if(loggedIn && !checkedFirstLogin) {			
			if(!isFirstLogin) {
				localStorage.setItem('isFirstLogin', 'true')
				navigate(onFirstLoginRedirectUrl, {replace: true});
			} else {
				navigate(onLoginRedirectUrl, {replace: true})
			}
		}			
	}, [loggedIn])

	const handleSubmit = async (e) => {
		if (e && e.preventDefault) e.preventDefault();
		const loginData = new FormData(e.target);
		const username = loginData.get("username");
		const password = loginData.get("password");

		let server_uuid = server; // from state
		let server_name = null;
		if (urlMatchedHost != null && "uuid" in urlMatchedHost){
			server_uuid = urlMatchedHost.uuid;
			server_name = urlMatchedHost.name;
		}

		if (!server_uuid) {
			setError("password");
			setErrorMsg("Please select server");
		} else {
			setError("");
			setErrorMsg("");
			setAccessingGym(true);			
			try {
				const res = await Axios.post(
					"https://yg73pjd0x8.execute-api.eu-north-1.amazonaws.com/dev/login",
					{
						username,
						password,						
						uuid: server_uuid,
					}
				);				
				localStorage.setItem("secretKey", "allowmein");
				localStorage.setItem("access_token", res.data.access_token);
				localStorage.setItem("refresh_token", res.data.refresh_token);
				localStorage.setItem("server_url", res.data.url);
				localStorage.setItem("server_uuid", server_uuid);
				localStorage.setItem("server_name", server_name);
				setLoggedIn(true);

				const user = await getAccount();									
				authActions.setUser(user);							
			} catch (err) {						
				setErrorMsg(
					get(
						err,
						"response.data.error_description",
						"Unable to login"					
					)
				);
				setAccessingGym(false);
			}			
		}
	};
	
	console.log("servers", servers)


	const handleChange = (event) => {
		const selectedServer = event.target.value;
		setServer(event.target.value);
		localStorage.setItem('lastUsedServer', selectedServer);		
	};

	const handleLanguageChange = (event) => {
		const selectedLanguage = event.target.value;
		setLanguage(selectedLanguage);
		i18n.changeLanguage(selectedLanguage);
	}
		
	console.log("Selected server", server)
	
	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<div style={{marginBottom: 10}}>
					<img src={logo} alt="Logo" />
				</div>				
				<Typography component="h1" variant="h5" fontWeight="500">
				{t('login_page.title')}
				</Typography>
				<Typography>
					{t('login_page.greet')}
				</Typography>
			{/* { server ? (
			<div>
				<h3>Selected server:</h3>
				<p>{server}</p>
			</div>
			) : (
				<p>No server found</p>
			)} */}
				<form
					className={classes.form}
					noValidate
					onSubmit={handleSubmit}
				>
					{loading && <p>Loading...</p>}
					{!loading && urlMatchedHost == null && (
						<FormControl required fullWidth variant="outlined">																				
							<InputLabel id="demo-simple-select-outlined-label">
								Server
							</InputLabel>							
							<Select
								labelId="demo-simple-select-outlined-label"
								id="demo-simple-select-outlined"								
								className={classes.select}
								value={server}						
								label="Server"		
								onChange={handleChange}								
								MenuProps={{
									PaperProps: {
									 className: classes.menuPaper,
									}
								}}											
							>												
							{servers}															
							</Select>
						</FormControl>
					)}
					{!loading && urlMatchedHost != null && 'name' in urlMatchedHost && (
						<Typography component="h1" variant="h6" textAlign="center">
								{urlMatchedHost.name}
						</Typography>
					)}				
					<TextField
						variant="outlined"
						className={classes.textField}
						margin="normal"
						required
						fullWidth
						id="username"
						label="Username"
						name="username"
						autoFocus
					/>
					<TextField						
						className={classes.textField}
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
					/>
						<FormControl required fullWidth>							
							<Select								
								id="demo-simple-select-outlined"
								className={classes.select}
								value={i18n.language}								
								onChange={handleLanguageChange}	
								MenuProps={{
									PaperProps: {
										className: classes.menuPaper,
									}
								}}																							
							>
							<MenuItem value="en">English</MenuItem>
							<MenuItem value="de">German</MenuItem>
							<MenuItem value="fi">Finnish</MenuItem>
							<MenuItem value="fr">French</MenuItem>
							<MenuItem value="sw">Swedish</MenuItem>
							<MenuItem value="no">Norwegian</MenuItem>
							<MenuItem value="dk">Danish</MenuItem>
							<MenuItem value="du">Dutch</MenuItem>
							<MenuItem value="it">Italian</MenuItem>
							<MenuItem value="zh_CN">Chinese Simplified</MenuItem>
							<MenuItem value="zh_TW">Chinese</MenuItem>
							</Select>
						</FormControl>
					<div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
					<Button						
						type="submit"						
						variant="contained"						
						className={classes.submit}
					>
						<Typography className={classes.submitText}>
						{t('login_page.sign_in_button')}
						</Typography>						
					</Button>
					<Typography style={{marginBottom: "10px"}} variant="body2" color="textSecondary" align="center">
					<InstallPWAButton  />
				</Typography>
					</div>
					<div>
						{errorMsg}
						{accessingGym && ( <span>Accessing gym server...</span>  )}
					</div>
				</form>
			</div>	
			<Box mt={4}>
				
				<Copyright />
			</Box>
		</Container>
	);
}
